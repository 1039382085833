export const socialList = [
  {
    name: 'instagram',
    url: 'https://www.instagram.com/thecelebrium/'
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/TheCelebrium'
  }
]
